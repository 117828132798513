html {
  padding: 0;
  margin: 0;
}

body {
  padding: 0;
  margin: 0;
}

svg,
img {
  -webkit-user-drag: none;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  -o-user-select: none;
  user-select: none;
  pointer-events: none;
}
