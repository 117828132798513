html, body {
  margin: 0;
  padding: 0;
}

svg, img {
  -webkit-user-drag: none;
  user-select: none;
  -o-user-select: none;
  pointer-events: none;
}

/*# sourceMappingURL=index.acfc3be6.css.map */
